import {
  CollectionPreferencesProps,
  PaginationProps,
  TableProps,
} from "@amzn/awsui-components-react";

export function getMatchesCountText(count: any) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const paginationLabels: PaginationProps.Labels = {
  nextPageLabel: "Next page",
  pageLabel: (pageNumber) => `Go to page ${pageNumber}`,
  previousPageLabel: "Previous page",
};
