import { ICreateTaxAreaModalProps } from "./definitions";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { CreateTaxAreaForm } from "../create-taxarea-form/CreateTaxAreaForm";
import styles from "./style.module.scss";
import { useState } from "react";

export const CreateTaxAreaModal: React.FC<ICreateTaxAreaModalProps> = ({
  onCreate,
  visible,
  setVisible,
}: ICreateTaxAreaModalProps) => {
  const [resetForm, setResetForm] = useState(0);
  const onDismiss = (e: any) => {
    if (e.detail.reason == "overlay") return;
    setVisible(false);
    setResetForm(resetForm + 1);
  };
  return (
    <Modal onDismiss={onDismiss} visible={visible} header="Add tax area">
      <CreateTaxAreaForm
        setVisible={setVisible}
        resetForm={resetForm}
        onCreate={onCreate}
      />
    </Modal>
  );
};
