import React, { Suspense } from "react";
import Loader from "components/loader";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "pages/home";
import Error404 from "pages/error-404";
/* PLOP_IMPORT_ROUTES */
import OutcomeOverview from "pages/outcome-overview";
import EditPayment from "pages/edit-payment";
import EditAssessment from "pages/edit-assessment";
import CreateAssessment from "pages/create-assessment";
import CreatePayment from "pages/create-payment";
import Reviews from "pages/reviews";
import DataReportSelection from "pages/data-report-selection";
import Tasks from "pages/tasks";
import AuditOverview from "pages/audit-overview";
import CreateAudit from "pages/create-audit";
import Audits from "pages/audits";
import Data from "pages/data";
import DataQuery from "pages/data-query";
import DataCreate from "../../pages/data-create";
import TaskInformation from "pages/task-information";
import { useAppSelector } from "app/store";
import TeamSettings from "pages/team_settings/TeamSettings";
// import { Provider } from "react-redux";
// import { store } from "app/store";

const Router: React.FC = (): JSX.Element => {
  const isNotUsSaltIndirect =
    useAppSelector((state) => state.audits.teamName) !== "us_salt_indirect";

  const navigateTo = (page: JSX.Element) => {
    if (isNotUsSaltIndirect) {
      return page;
    }
    if (page.type && page.type.name == "TeamSettings") {
      return <Navigate replace to="/team" />;
    }
    return <Navigate replace to="/data/" />;
  };

  return (
    <Suspense fallback={<Loader loadingText="Loading..." />}>
      {/* <Provider store={store}> */}
      <Routes>
        <Route path="/" element={<Navigate replace to="/audits/" />} />

        {/* PLOP_INJECT_ROUTES */}
        <Route
          path="/audit/:auditId/edit-payment/:paymentId"
          element={navigateTo(<EditPayment />)}
        />
        <Route
          path="/audit/:auditId/edit-assessment/:assessmentId"
          element={navigateTo(<EditAssessment />)}
        />
        <Route
          path="/audit/:auditId/create-assessment"
          element={navigateTo(<CreateAssessment />)}
        />
        <Route
          path="/audit/:auditId/create-payment"
          element={navigateTo(<CreatePayment />)}
        />
        <Route
          path="/audit/:auditId/assessment/:assessmentId"
          element={navigateTo(<OutcomeOverview />)}
        />
        <Route
          path="/audit/:auditId/payment/:paymentId"
          element={navigateTo(<OutcomeOverview />)}
        />
        <Route path="/team" element={<TeamSettings />} />
        <Route path="/tasks" element={navigateTo(<Tasks />)} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/task/:taskId" element={navigateTo(<TaskInformation />)} />
        <Route path="/audit/:auditId" element={navigateTo(<AuditOverview />)} />
        <Route
          path="/audit/create-audit"
          element={navigateTo(<CreateAudit />)}
        />
        <Route
          path="/audit/:auditId/data/:queryId"
          element={navigateTo(<DataQuery />)}
        />
        <Route path="/audits/" element={navigateTo(<Audits />)} />
        <Route path="/data/" element={<Data />} />
        <Route path="/data/create" element={<DataCreate />} />
        <Route path="/data/:queryId" element={<DataQuery />} />
        <Route
          path="/data/data-report-selection"
          element={<DataReportSelection />}
        />

        {/* Must be the last route */}
        <Route path="*" element={<Error404 />} />
      </Routes>
      {/* </Provider> */}
    </Suspense>
  );
};

export default Router;
