import { mainApi } from "services/main";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeams: builder.query<any, void>({
      query: () => ({
        url: "teams",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetTeamsQuery } = extendedApi;
