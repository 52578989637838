import {
  Box,
  Button,
  FormField,
  RadioGroup,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import { get, useForm } from "react-hook-form";
import {
  ControlledInput,
  ControlledSelect,
  ControlledTextarea,
} from "@amzn/react-hook-form-polaris";
import { zodResolver } from "@hookform/resolvers/zod";
import globals from "app/style.module.scss";
import styles from "app/style.module.scss";
import { IEditTaxAreaFormProps } from "./definitions";
import {
  updateTaxAreaSchema,
  UpdateTaxAreaFormFields,
  TaxArea,
} from "../../models/schemas";
import React, { useEffect } from "react";
import { useAppSelector } from "app/store";

export const EditTaxAreaForm: React.FC<IEditTaxAreaFormProps> = (
  props: IEditTaxAreaFormProps
) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset,
    setValue,
  } = useForm<UpdateTaxAreaFormFields>({
    mode: "onBlur",
    resolver: zodResolver(updateTaxAreaSchema),
    defaultValues: {
      title: props.selectedTaxArea.title,
      category: props.selectedTaxArea.category,
      status: props.selectedTaxArea.status,
      description: props.selectedTaxArea.description,
    },
  });
  const [disableCreate, setDisableCreate] = React.useState(false);

  useEffect(() => {
    reset();
  }, [props.resetForm, reset]);

  const data: any = useAppSelector((state) => state.uiContent);

  return (
    <form
      onSubmit={handleSubmit((values) => {
        props.onEdit(values);
        reset();
      })}
    >
      <SpaceBetween direction="vertical" size="l">
        <FormField label="Title" errorText={get(errors, "title.message")}>
          <ControlledInput name="title" control={control} />
        </FormField>
        <FormField label="Category" errorText={get(errors, "category.message")}>
          <ControlledSelect
            name="category"
            options={data.categoryOptions}
            control={control}
          />
        </FormField>
        <FormField label="Status" errorText={get(errors, "status.message")}>
          <RadioGroup
            name="status"
            value={watch("status")}
            items={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            onChange={(event) => {
              setValue("status", event.detail.value);
            }}
          />
        </FormField>
        <div className={styles.descriptionBox}>
          <FormField
            label="Description"
            errorText={get(errors, "description.message")}
          >
            <ControlledTextarea name="description" control={control} rows={3} />
          </FormField>
        </div>
        <Box float="right">
          <Button
            formAction="none"
            onClick={() => {
              reset();
              props.setVisible(false);
            }}
            variant="link"
          >
            Cancel
          </Button>
          <Button
            disabled={disableCreate}
            variant="primary"
            formAction="submit"
          >
            Edit
          </Button>
        </Box>
      </SpaceBetween>
    </form>
  );
};
