import {
  Box,
  Button,
  FormField,
  RadioGroup,
  SpaceBetween,
} from "@amzn/awsui-components-react/polaris";
import { get, useForm } from "react-hook-form";
import {
  ControlledInput,
  ControlledSelect,
  ControlledTextarea,
} from "@amzn/react-hook-form-polaris";
import { zodResolver } from "@hookform/resolvers/zod";
import styles from "./style.module.scss";
import { ICreateTaxAreaFormProps } from "./definitions";
import {
  createTaxAreaSchema,
  CreateTaxAreaFormFields,
} from "../../models/schemas";
import React, { useEffect } from "react";
import { useAppSelector } from "app/store";

export const CreateTaxAreaForm: React.FC<ICreateTaxAreaFormProps> = (
  props: ICreateTaxAreaFormProps
) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset,
    setValue,
  } = useForm<CreateTaxAreaFormFields>({
    mode: "onBlur",
    resolver: zodResolver(createTaxAreaSchema),
    defaultValues: {
      status: "Active",
    },
  });

  useEffect(() => {
    reset();
  }, [props.resetForm, reset]);

  const data: any = useAppSelector((state) => state.uiContent);

  return (
    <form
      onSubmit={handleSubmit((values) => {
        props.onCreate(values);
        reset();
      })}
    >
      <SpaceBetween direction="vertical" size="l">
        <FormField label="Title" errorText={get(errors, "title.message")}>
          <ControlledInput name="title" control={control} placeholder="" />
        </FormField>
        <FormField label="Category" errorText={get(errors, "category.message")}>
          <ControlledSelect
            name="category"
            options={data.categoryOptions}
            control={control}
            placeholder="Choose an option"
          />
        </FormField>
        <FormField label="Status" errorText={get(errors, "status.message")}>
          <RadioGroup
            name="status"
            value={watch("status")}
            items={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
            onChange={(event) => {
              setValue("status", event.detail.value);
            }}
          />
        </FormField>
        <div className={styles.descriptionBox}>
          <FormField
            label="Description"
            errorText={get(errors, "description.message")}
          >
            <ControlledTextarea
              name="description"
              control={control}
              placeholder=""
              rows={3}
            />
          </FormField>
        </div>
        <Box float="right">
          <Button
            formAction="none"
            onClick={() => {
              reset();
              props.setVisible(false);
            }}
            variant="link"
          >
            Cancel
          </Button>
          <Button variant="primary" formAction="submit">
            Add
          </Button>
        </Box>
      </SpaceBetween>
    </form>
  );
};
