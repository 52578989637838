import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IUiContentState,
  ListMemberResponse,
  UIContentResponse,
} from "./definitions";
import { BaseOption } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";

const initialState: IUiContentState = {
  countryOptions: [{ label: "No country", value: "No country" }],
  entityOptions: [{ label: "No entity", value: "No entity" }],
  governmentEntityOptions: [
    { label: "No tax authority", value: "No tax authority" },
  ],
  jurisdictionLevelOptions: [
    { label: "No jurisdiction level", value: "No jurisdiction level" },
  ],
  taxTypeOptions: [{ label: "No tax type", value: "No tax type" }],
  ownerOptions: [{ label: "No owner", value: "No owner" }],
  categoryOptions: [{ label: "No category", value: "No category" }],
  assessmentTypeOptions: [
    { label: "No assessment type", value: "No assessment type" },
  ],
  paymentTypeOptions: [{ label: "No payment type", value: "No payment type" }],
};

const mapOptions = (values: Array<string>) => {
  return values
    .map((value: string) => ({
      label: value,
      value: value,
    }))
    .sort((a: { label: string }, b: { label: string }) =>
      a.label > b.label ? 1 : -1
    );
};

const mapOptionsUnsort = (values: Array<string>) => {
  return values.map((value: string) => ({
    label: value,
    value: value,
  }));
};

const mapOptionsWithGroup = (values: Array<BaseOption>) => {
  var items: { label: string | undefined; options: BaseOption[] }[] = [];
  var groupNames = new Set(values.map((value) => value.labelTag));
  groupNames.forEach((groupName) => {
    var entries: Array<BaseOption> = [];
    values.forEach((value) => {
      if (value.labelTag == groupName) {
        var entry = {
          label: value.label ?? "",
          value: value.value ?? "",
          description: value.value ?? "",
        };

        entries.push(entry);
      }
    });
    entries.sort((a: BaseOption, b: BaseOption) => {
      if (a.label === undefined) return 1;
      if (b.label === undefined) return -1;

      return a.label > b.label ? 1 : -1;
    });
    var group = {
      label: groupName,
      options: entries,
    };

    items.push(group);
  });
  return items;
};

const mapOptionsObj = (values: Array<BaseOption>) => {
  return values
    .map((value: BaseOption) => ({
      label: value.label ?? "",
      value: value.value ?? "",
      description: value.value ?? "",
    }))
    .sort((a: { label: string }, b: { label: string }) =>
      a.label > b.label ? 1 : -1
    );
};

export const uiContentSlice = createSlice({
  name: "uiContent",
  initialState,
  reducers: {
    uiContentLoaded: (state, action: PayloadAction<UIContentResponse>) => {
      if (action.payload.content === null) {
        return;
      }
      const uiContent = JSON.parse(action.payload.content);
      if (uiContent.country !== undefined)
        state.countryOptions = mapOptions(uiContent.country);

      if (uiContent.entity !== undefined)
        state.entityOptions = mapOptionsObj(uiContent.entity);

      if (uiContent.governmententity !== undefined)
        state.governmentEntityOptions = mapOptionsWithGroup(
          uiContent.governmententity
        );

      if (uiContent.jurisdictionlevel !== undefined)
        state.jurisdictionLevelOptions = mapOptionsUnsort(
          uiContent.jurisdictionlevel
        );
      if (uiContent.taxtype !== undefined)
        state.taxTypeOptions = mapOptions(uiContent.taxtype);
      if (uiContent.category !== undefined)
        state.categoryOptions = mapOptions(uiContent.category);
      if (uiContent.assessmenttype !== undefined)
        state.assessmentTypeOptions = mapOptions(uiContent.assessmenttype);
      if (uiContent.paymenttype !== undefined)
        state.paymentTypeOptions = mapOptions(uiContent.paymenttype);
    },
    teamMemberLoaded: (state, action: PayloadAction<ListMemberResponse>) => {
      if (action.payload.members === null) {
        return;
      }
      state.ownerOptions = mapOptions(action.payload.members);
    },
  },
});

export const { uiContentLoaded, teamMemberLoaded } = uiContentSlice.actions;

export default uiContentSlice.reducer;
