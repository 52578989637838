import moment from "moment";
import * as z from "zod";
import { isValidBrazilCnpj, isValidBrazilCpf } from "../utils/validation";
import { customAttributesDataObjectSchema } from "./generateCustomAttributesSchema";

const zod = {
  string: () => z.string().trim().min(1, { message: "Required." }),
};

export const auditSchema = z.object({
  auditId: zod.string(),
  auditName: zod.string(),
  country: zod.string(),
  teamName: zod.string(),
  status: zod.string(),
  entity: zod.string(),
  auditNumber: z.string().optional(),
  taxNumber: z.string().optional(),
  governmentEntity: z.string(),
  jurisdictionLevel: z.string(),
  taxType: z
    .string({ required_error: "Must have at least one tax type" })
    .array()
    .nonempty({ message: "Must have at least one tax type" })
    .max(8, { message: "Cannot have more than 8 tax type" }),
  owner: zod.string(),
  auditStartDate: z.number(),
  auditStartDateString: zod.string(),
  auditEndDate: z.number(),
  auditEndDateString: zod.string(),
  auditInitiationDate: z.number(),
  auditInitiationDateString: zod.string(),
  auditCloseDate: z.number().optional(),
  auditCloseDateString: z.string().optional(),
  statuteOfLimitations: z.number().optional(),
  statuteOfLimitationsString: z.string().optional(),
  deleted: z.boolean(),
  statusActionOwner: z.string().optional(),
  statusDetail: z.string().optional(),
  pandaRefId: zod.string().optional(),
});

export const createAuditSchema = auditSchema
  .omit({
    auditId: true,
    auditName: true,
    teamName: true,
    status: true,
    deleted: true,
    auditStartDate: true, //UI maps to auditStartDateString
    auditEndDate: true, //UI maps to auditEndDateString
    auditInitiationDate: true, //UI maps to auditInitiationDateString
    auditCloseDate: true, //UI maps to auditCloseDateString
    statuteOfLimitations: true, //UI maps to statuteOfLimitationsString
  })
  .refine((data) => data.auditEndDateString > data.auditStartDateString, {
    message: "Audit end date should be after audit start date",
    path: ["auditEndDateString"],
  });

export const editAuditSchema = auditSchema
  .omit({
    auditId: true,
    auditName: true,
    teamName: true,
    status: true,
    deleted: true,
    auditStartDate: true, //UI maps to auditStartDateString
    auditEndDate: true, //UI maps to auditEndDateString
    auditInitiationDate: true, //UI maps to auditInitiationDateString
    auditCloseDate: true, //UI maps to auditCloseDateString
    statuteOfLimitations: true, //UI maps to statuteOfLimitationsString
  })
  .refine((data) => data.auditEndDateString > data.auditStartDateString, {
    message: "Audit end date should be after audit start date",
    path: ["auditEndDateString"],
  });

export const updateAuditStatusSchema = z.object({
  status: zod.string(),
  statusActionOwner: zod.string(),
  statusDetail: zod.string(),
});

export const createMarketplaceSellerDataSchema = z.object({
  reportName: zod.string(),
  sellerName: z.string().optional(),
  sellerTaxId: z
    .string()
    .refine(
      (data) =>
        data.length === 0 || isValidBrazilCpf(data) || isValidBrazilCnpj(data),
      {
        message: "Seller id must be a CPF or CNPJ",
      }
    ),
  sellerId: z.string().optional(),
});

// Customer lookup form schema
export const encryptedidRegex = /^[A-Z0-9]+$/;
export const decryptedIdRegex = /^[0-9]+$/;

export const isValidId = (id: string, isDataEncrypted: string): boolean => {
  if (isDataEncrypted === "Yes")
    return id.length >= 3 && encryptedidRegex.test(id);
  return id.length >= 3 && decryptedIdRegex.test(id);
};

export const createCustomerLookupDataSchema = z
  .object({
    reportName: zod.string(),
    searchType: zod.string(),
    idInput: z
      .string()
      .transform((value) =>
        value
          .split(",")
          .map((val) => val.trim())
          .filter((val) => val.length > 0)
          .join(",")
      )
      .optional(),
    // addressInfoInputIds: z.string().optional(),
    customerInfoSearchIdOption: z.string().optional(), // when user choose to search by customerInfo, we have two choices: buyerID and buyerExemptionId
    filesUploaded: z
      .array(z.instanceof(File))
      .optional()
      .refine(
        (value) => {
          if (value === undefined || value.length === 0) return true;
          return value[0].size < 10000000;
        },
        { message: "The file size is over the limit" }
      ),
    isDataEncrypted: zod.string(),
  })
  .superRefine((data, ctx) => {
    if (data.idInput) {
      const ids = data.idInput
        .split(",")
        .map((id) => id.trim())
        .filter(Boolean);
      if (
        ids.length > 10 ||
        !ids.every((id) => isValidId(id, data.isDataEncrypted))
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["idInput"],
          message:
            "Maximum of 10 IDs allowed. Each encrypted ID should contain uppercase letters and digits only. Each decrypted ID should contain digits only.",
        });
      }
    }
  });

export const criticalDataUsageComponentSchema = z.object({
  reportPurpose: zod.string().trim().min(1, { message: "Required." }),
  isUserAgreed: z.boolean().refine((value) => value === true, {
    message: "You must accept the terms and conditions",
  }),
});

export const createDataBrazilTransactionReportSchema = z
  .object({
    reportName: zod.string(),
    entity: z.string().min(1, { message: "Required." }),
    state: zod.string(),
    startDate: zod.string(),
    endDate: zod.string(),
    sellerTaxId: z
      .string()
      .refine(
        (data) =>
          data.length > 0 &&
          (isValidBrazilCpf(data) || isValidBrazilCnpj(data)),
        {
          message: "Seller id must be a CPF or CNPJ",
        }
      ),
  })
  .superRefine((data, ctx) => {
    if (data.startDate > data.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["endDate"],
        message: "End date cannot be before start date",
      });
    }
  });

export const taskBasicSchema = z.object({
  taskId: zod.string().optional(),
  taskType: zod.string(),
  parentTaskId: zod.string(),
  teamName: zod.string(),
  taskName: zod.string(),
  owners: zod.string(),
  status: z.string().optional(),
  actualStartDate: z.number().optional(),
  actualStartDateString: z.string().optional(),
  actualCompletionDate: z.number().optional(),
  actualCompletionDateString: z.string().optional(),
  milestone: z.boolean().optional(),
  description: z.string().optional(),
  deleted: z.boolean(),
  progress: z.string().optional(),
  taskGroupName: z.string().optional(),
  auditName: z.string().optional(),
});

export const taskGroupSchema = taskBasicSchema.extend({
  plannedStartDate: z.number(),
  plannedStartDateString: zod.string(),
  dueDate: z.number(),
  dueDateString: zod.string(),
});

export const taskSchema = taskBasicSchema.extend({
  plannedStartDate: z.number().optional(),
  plannedStartDateString: z.string().optional(),
  dueDate: z.number().optional(),
  dueDateString: z.string().optional(),
});

export const createTaskSchema = taskSchema.omit({
  taskId: true,
  taskType: true,
  parentTaskId: true,
  teamName: true,
  plannedStartDate: true,
  actualStartDate: true,
  dueDate: true,
  actualCompletionDate: true,
  deleted: true,
});

export const createTaskGroupSchema = taskGroupSchema
  .omit({
    deleted: true,
    owners: true,
    teamName: true,
    plannedStartDate: true,
    dueDate: true,
    actualStartDate: true,
    actualCompletionDate: true,
    parentTaskId: true,
    taskType: true,
  })
  .refine(
    (input) => {
      if (
        moment(input.dueDateString).isAfter(
          moment(input.plannedStartDateString)
        )
      ) {
        return true;
      }
      return false;
    },
    {
      message: "Planned end date should be after planned start date",
      path: ["dueDateString"],
    }
  );

export const updateTaskSchema = taskSchema.omit({
  deleted: true,
  plannedStartDate: true,
  dueDate: true,
  actualStartDate: true,
  actualCompletionDate: true,
});
export type UpdateTaskFormFields = z.infer<typeof updateTaskGroupSchema>;

export const updateTaskGroupSchema = taskGroupSchema
  .omit({
    deleted: true,
    owners: true,
    plannedStartDate: true,
    dueDate: true,
    actualStartDate: true,
    actualCompletionDate: true,
    parentTaskId: true,
    taskType: true,
  })
  .refine(
    (input) => {
      if (
        moment(input.dueDateString).isAfter(
          moment(input.plannedStartDateString)
        )
      ) {
        return true;
      }
      return false;
    },
    {
      message: "Planned end date should be after planned start date",
      path: ["dueDateString"],
    }
  );
export const commentSchema = z.object({
  commentId: zod.string(),
  parentId: zod.string(),
  actorId: zod.string(),
  actorType: zod.string(),
  attributes: z.record(z.string()),
  content: zod.string(),
  version: z.number(),
  creationTime: z.number(),
  application: zod.string(),
  responses: z.array(z.unknown()).optional(),
});

export const requestReviewSchema = z.object({
  reviewType: z.string({
    required_error: "Please select a review type",
  }),
  reviewers: z
    .string({ required_error: "Must have at least one reviewer" })
    .array()
    .nonempty({ message: "Must have at least one reviewer" })
    .max(5, { message: "Cannot have more than 5 reviewers" }),
  dueDateString: z.string({ required_error: "Due date is required" }),
  messageForReviewers: z.optional(
    z.string().max(2000, "Message must be 2000 characters or less")
  ),
});

export const editReviewSchema = z.object({
  reviewType: z.string({
    required_error: "Please select a review type",
  }),
  reviewers: z
    .string()
    .array()
    .max(5, { message: "Cannot have more than 5 reviewers" }),
  dueDateString: z.string({ required_error: "Due date is required" }),
  messageForReviewers: z.optional(
    z.string().max(2000, "Message must be 2000 characters or less")
  ),
});

export const reviewApprovalSchema = z
  .object({
    responseType: z
      .string({
        required_error: "Please select a response type",
      })
      .nonempty("Response type is required"),
    reassignReviewers: z
      .string()
      .array()
      .max(5, { message: "Cannot have more than 5 reviewers" })
      .optional(),
    comments: z.optional(
      z.string().max(2000, "Message must be 2000 characters or less")
    ),
  })
  .refine(
    (data) => {
      if (
        data.responseType === "Reassigned" &&
        data.reassignReviewers !== undefined &&
        data.reassignReviewers.length > 0
      ) {
        return true;
      } else if (
        data.responseType !== "Reassigned" &&
        (data.reassignReviewers == null || data.reassignReviewers?.length === 0)
      ) {
        return true;
      }
      return false;
    },
    {
      message: "Reviewer required",
      path: ["reassignReviewers"],
    }
  );

export const reviewSchema = z.object({
  reviewId: zod.string(),
  auditId: zod.string(),
  taskGroupId: zod.string(),
  parentTaskId: zod.string(),
  reviewType: zod.string(),
  teamName: zod.string(),
  status: zod.string(),
  requestedBy: z.string(),
  reviewer: z.string(),
  deleted: z.boolean(),
  dueDate: z.number().optional(),
  modifiedAt: z.number().optional(),
  dueDateString: z.string(),
  reviewDate: z.string().optional(),
  auditName: z.string().optional(),
  taskGroupName: z.string().optional(),
  taskName: z.string().optional(),
  comments: z.string().optional(),
  messageForReviewers: z.string().optional(),
});

const teamSchema = z.object({
  teamId: z.string(),
  ldapGroupId: z.string(),
  teamDescription: z.string(),
  auditTitleFormula: z.string(),
  isCritical: z.boolean(),
  teamCustomAttributesSchema: z
    .record(
      z.string(),
      customAttributesDataObjectSchema
      // z.object({
      //   entityCustomAttributesSchemaList: customAttributesDataObjectSchema,
      // })
    )
    .optional(),
});

export const taxAreaSchema = z.object({
  taxAreaId: z.string(),
  teamName: z.string().optional(),
  title: z
    .string({
      required_error: "Title is required.",
    })
    .min(1, "Title is required")
    .max(255, "Title must be less than 255 characters"),
  category: z.string({
    required_error: "Category is required.",
  }),
  status: z.string(),
  description: z
    .string()
    .max(10000, "Description limited to 10000 characters")
    .optional(),
  createdBy: z.string().optional(),
  createdAt: z.string().optional(),
  modifiedBy: z.string().optional(),
  modifiedAt: z.string().optional(),
});

export const createTaxAreaSchema = taxAreaSchema
  .omit({
    taxAreaId: true,
    teamName: true,
  })
  .refine(
    (data) => {
      if (
        data.title.length > 0 &&
        data.category.length > 0 &&
        data.status.length > 0
      ) {
        return true;
      } else return false;
    },
    {
      message: "Fields: title, category, and status are required",
      path: ["createTaxArea"],
    }
  );

export const updateTaxAreaSchema = taxAreaSchema
  .omit({
    taxAreaId: true,
    teamName: true,
  })
  .refine(
    (data) => {
      if (
        data.title.length > 0 &&
        data.category.length > 0 &&
        data.status.length > 0
      ) {
        return true;
      } else return false;
    },
    {
      message: "Fields: title, category, and status are required",
      path: ["createTaxArea"],
    }
  );

export const outcomeBreakdownSchema = z.object({
  outcomeBreakdownId: z.string(),
  parentId: z.string(),
  totalTaxAmount: z.string(),
  totalPenaltyAmount: z.string(),
  totalInterestAmount: z.string(),
  totalOtherAmount: z.string(),
  type: z.string(),
  category: z.string(),
});
export const createOutcomeBreakdownSchema = outcomeBreakdownSchema.omit({
  outcomeBreakdownId: true,
  parentId: true,
});

export enum OutcomeBreakdownType {
  TAXYEAR = "Tax year",
  TAXAREA = "Tax area",
}

export const assessmentSchema = z.object({
  assessmentId: z.string(),
  auditId: z.string(),
  type: z.string({ required_error: "Required" }),
  dateReceived: z
    .string({ required_error: "Required" })
    .refine((val) => val !== "", {
      message: "Required",
    }),
  totalTaxAmount: z.number({ required_error: "Total tax amount is required" }),
  totalPenaltyAmount: z.number({
    required_error: "Total penalty amount is required",
  }),
  totalInterestAmount: z.number({
    required_error: "Total interest amount is required",
  }),
  totalOtherAmount: z.number({
    required_error: "Total other amount is required",
  }),
  notes: z.string().max(1500, "Notes limited to 1500 characters").optional(),
  createdBy: z.string().optional(),
  createdAt: z.string().optional(),
  modifiedBy: z.string().optional(),
  modifiedAt: z.string().optional(),
  taxYearBreakdown: z.array(outcomeBreakdownSchema).optional(),
  taxAreaBreakdown: z.array(outcomeBreakdownSchema).optional(),
});
export const createAssessmentSchema = assessmentSchema.omit({
  assessmentId: true,
  auditId: true,
});

export const paymentSchema = z.object({
  paymentId: z.string(),
  auditId: z.string(),
  type: z.string(),
  paymentDate: z.string().refine((val) => val !== "", {
    message: "Required",
  }),
  paymentRefNumber: z
    .string()
    .optional()
    .refine(
      (val) => {
        if (!val) return true;
        return val.length === 7 && /^\d+$/.test(val);
      },
      {
        message: "Payment reference number must be exactly 7 digits",
      }
    ),
  trackingRefNumber: z
    .string()
    .optional()
    .refine(
      (val) => {
        if (!val) return true;
        return val.length === 20 && /^\d+$/.test(val);
      },
      {
        message: "Tracking reference number must be exactly 20 digits",
      }
    ),
  totalTaxAmount: z.number({ required_error: "Total tax amount is required" }),
  totalPenaltyAmount: z.number({
    required_error: "Total penalty amount is required",
  }),
  totalInterestAmount: z.number({
    required_error: "Total interest amount is required",
  }),
  totalOtherAmount: z.number({
    required_error: "Total other amount is required",
  }),
  notes: z.string().max(1500, "Notes limited to 1500 characters").optional(),
  createdBy: z.string().optional(),
  createdAt: z.string().optional(),
  modifiedBy: z.string().optional(),
  modifiedAt: z.string().optional(),
  taxYearBreakdown: z.array(outcomeBreakdownSchema).optional(),
  taxAreaBreakdown: z.array(outcomeBreakdownSchema).optional(),
});
export const createPaymentSchema = paymentSchema.omit({
  paymentId: true,
  auditId: true,
});

export type CreateAssessmentFormFields = z.infer<typeof createAssessmentSchema>;

export type CreatePaymentFormFields = z.infer<typeof createPaymentSchema>;

export type CreateOutcomeBreakdownEntry = z.infer<
  typeof createOutcomeBreakdownSchema
>;

export type CreateTaskGroupFormFields = z.infer<typeof createTaskGroupSchema>;
export type UpdateTaskGroupFormFields = z.infer<typeof updateTaskGroupSchema>;

export type CreateTaxAreaFormFields = z.infer<typeof createTaxAreaSchema>;
export type UpdateTaxAreaFormFields = z.infer<typeof updateTaxAreaSchema>;

// Schema derived types
export type Assessment = z.infer<typeof assessmentSchema>;
export type Payment = z.infer<typeof paymentSchema>;
export type OutcomeBreakdown = z.infer<typeof outcomeBreakdownSchema>;
export type Audit = z.infer<typeof auditSchema>;
export type Task = z.infer<typeof taskSchema> & { createdAt?: number };
export type Comment = z.infer<typeof commentSchema>;
export type Review = z.infer<typeof reviewSchema>;
export type Team = z.infer<typeof teamSchema>;
export type TeamNonCustomAttributes = Omit<Team, "teamCustomAttributesSchema">;
export type TaxArea = z.infer<typeof taxAreaSchema>;
