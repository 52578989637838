import { useAppSelector } from "app/store";

export const useUserNameSelector = () => {
  const userName = useAppSelector((state) => state.auth.user.alias);
  return { userName };
};

export const useTeamNameSelector = () => {
  const teamName = useAppSelector((state) => state.audits.teamName);
  return { teamName };
};

export { useAppSelector };
