import { useEffect } from "react";
import { uiContentLoaded, teamMemberLoaded } from "./slice";
import { useAppDispatch, useAppSelector } from "app/store";
import { useGetUIContentQuery, useGetListMemberQuery } from "./endpoints";

export const useUiContent = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.uiContent);
  const teamName = useAppSelector((state) => state.audits.teamName);
  const { data } = useGetUIContentQuery(
    {
      teamName: teamName,
      page: "createaudit",
    },
    {
      skip: !teamName,
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(uiContentLoaded(data));
    }
  }, [dispatch, data]);

  return {
    uiContent: selector,
  };
};

export const useTeamMembers = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.uiContent);
  const teamName = useAppSelector((state) => state.audits.teamName);
  const { data } = useGetListMemberQuery(
    {
      teamName: teamName,
    },
    {
      skip: !teamName,
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(teamMemberLoaded(data));
    }
  }, [dispatch, data]);

  return {
    uiContent: selector,
  };
};
