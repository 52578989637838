import { mainApi } from "services/main";
import {
  ListMemberRequest,
  ListMemberResponse,
  UIContentRequest,
  UIContentResponse,
} from "./definitions";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getUIContent: builder.query<UIContentResponse, UIContentRequest>({
      query: (request: UIContentRequest) => ({
        url: "uicontent/" + request.teamName + "/" + request.page,
      }),
    }),
    getListMember: builder.query<ListMemberResponse, ListMemberRequest>({
      query: (request: ListMemberRequest) => ({
        url: "permission/members/" + request.teamName,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetUIContentQuery, useGetListMemberQuery, usePrefetch } =
  extendedApi;
