import React from "react";
import { IEmptyStateProps } from "./definitions";
import { Box } from "@amzn/awsui-components-react";

function EmptyState({ title, subtitle, action }: IEmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: "s" }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export default EmptyState;
