import { IEditTaxAreaModalProps } from "./definitions";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { EditTaxAreaForm } from "../edit-taxarea-form/EditTaxAreaForm";
import styles from "./style.module.scss";
import { useState } from "react";

export const EditTaxAreaModal: React.FC<IEditTaxAreaModalProps> = ({
  onEdit,
  visible,
  setVisible,
  selectedTaxArea,
}: IEditTaxAreaModalProps) => {
  const [resetForm, setResetForm] = useState(0);
  const onDismiss = (e: any) => {
    if (e.detail.reason == "overlay") return;
    setVisible(false);
    setResetForm(resetForm + 1);
  };
  return (
    <Modal onDismiss={onDismiss} visible={visible} header="Edit tax area">
      <EditTaxAreaForm
        setVisible={setVisible}
        resetForm={resetForm}
        onEdit={onEdit}
        selectedTaxArea={selectedTaxArea}
      />
    </Modal>
  );
};
