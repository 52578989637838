import { useTranslation } from "react-i18next";

/**
 * Hook for changing the html document title
 */
export const useDocumentTitle = () => {
  const { t } = useTranslation();

  return (title: string) => {
    document.title = `${t("app_name")} | ${title}`;
  };
};
