import { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";
import { useAppSelector } from "app/store";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Hook for handling side navigation events
 */
export const useOnFollowHandler = () => {
  const navigate = useNavigate();

  return (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      navigate(event.detail.href, { replace: true });
    }
  };
};

export const useNavConfig = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const teamName = useAppSelector((state) => state.audits.teamName);
  const isNotUsSaltIndirect = teamName !== "us_salt_indirect";

  const sideNavProps1 = [
    {
      type: "link",
      text: t("pages.audits.title"),
      href: "/audits/",
    },
    { type: "link", text: t("pages.tasks.title"), href: "/tasks" },
    { type: "link", text: t("pages.reviews.title"), href: "/reviews" },
    {
      type: "link",
      text: t("pages.data.title"),
      href: "/data/",
    },
    { type: "divider" },
    {
      type: "link",
      text: t("pages.team_settings.title"),
      href: "/team/",
    },
  ];

  const sideNavProps2 = [
    {
      type: "link",
      text: t("pages.data.title"),
      href: "/data/",
    },
    { type: "divider" },
    {
      type: "link",
      text: t("pages.team_settings.title"),
      href: "/team/",
    },
  ];

  const sideNavTeamProps = [
    {
      type: "link",
      text: t("pages.team_settings.title"),
      href: "/team/",
    },
    {
      //for later not yet implemented
      type: "link",
      text: "Notifications",
      href: "/",
    },
  ];

  const sideNavProps = isNotUsSaltIndirect ? sideNavProps1 : sideNavProps2;
  return {
    header: {
      href: "",
      text: t("left_nav.title"),
    } as SideNavigationProps.Header,
    items: sideNavProps as SideNavigationProps.Item[],
    activeHref: location.pathname,
  };
};
