import {
  BLANK_CUSTOMER_LOOKUP_CONFIG,
  BLANK_MARKETPLACE_SELLER_LOOKUP_CONFIG,
  IDataCreateState,
  TEAM_LEVEL_DATA_REPORT_KEY_NAME,
} from "./definitions";
import { useAppSelector } from "app/store";
import { GaiaConstants } from "../../utils";

export const useGetDataLookupState = (queryType: string, auditId: string) => {
  var dataLookupStateName: string;
  var blankReport;
  // switch query type to define the state name
  switch (queryType) {
    case "CUSTOMER_INFORMATION_LOOKUP":
      dataLookupStateName = "customerLookup";
      blankReport = BLANK_CUSTOMER_LOOKUP_CONFIG;
      break;
    case "CUSTOMER_INFORMATION_LOOKUP_BY_BRAAVOS":
      dataLookupStateName = "customerLookup";
      blankReport = BLANK_CUSTOMER_LOOKUP_CONFIG;
      break;
    case "BrazilMarketPlaceSellerLookup":
      dataLookupStateName = "marketplaceSellerLookup";
      blankReport = BLANK_MARKETPLACE_SELLER_LOOKUP_CONFIG;
      break;
    default:
      dataLookupStateName = "customerLookup";
      blankReport = BLANK_CUSTOMER_LOOKUP_CONFIG;
      break;
  }

  const initialDataUsageState = useAppSelector(
    (state) => state.dataCreate[dataLookupStateName as keyof IDataCreateState]
  );
  return auditId
    ? auditId in initialDataUsageState
      ? initialDataUsageState[auditId]
      : blankReport
    : initialDataUsageState[TEAM_LEVEL_DATA_REPORT_KEY_NAME];
};

export const useGetCustomerLookupState = (auditId: string) => {
  const customerLookupState = useAppSelector(
    (state) => state.dataCreate.customerLookup
  );
  return auditId !== ""
    ? auditId in customerLookupState
      ? customerLookupState[auditId]
      : BLANK_CUSTOMER_LOOKUP_CONFIG
    : customerLookupState[TEAM_LEVEL_DATA_REPORT_KEY_NAME];
};

export const useGetMarketsellerLookupState = (auditId: string) => {
  const initialMarketplaceSellerLookupState = useAppSelector(
    (state) => state.dataCreate.marketplaceSellerLookup
  );
  return auditId
    ? auditId in initialMarketplaceSellerLookupState
      ? initialMarketplaceSellerLookupState[auditId]
      : BLANK_MARKETPLACE_SELLER_LOOKUP_CONFIG
    : initialMarketplaceSellerLookupState[TEAM_LEVEL_DATA_REPORT_KEY_NAME];
};
