import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITeamSettingsState } from "./definitions";
import {
  basicAuditFormSchema,
  basicAuditSchema,
} from "models/generateAuditSchemas";

const initialState: ITeamSettingsState = {
  // TODO: remove selectedTeam and isUserCriticalInTeam and update corresponding components to use the new attributes below
  selectedTeam: {},
  isUserCriticalInTeam: false,
  // This is the team main meta data from backend
  selectedTeamMainMetaData: {
    teamId: "",
    teamDescription: "",
    auditTitleFormula: "",
    ldapGroupId: "",
    isCritical: false,
  },
  auditSchemas: {
    // This is the schema for all attributes when we try to create/edit the audit including the visible and invisible attributes
    auditSchema: basicAuditSchema,
    // This is the schema for creating/editing the audit including all the visible attributes
    auditFormSchema: basicAuditFormSchema,
  },
};

export const teamSettingsSlice = createSlice({
  name: "teamSettings",
  initialState,
  reducers: {
    setTeamById: (state, action) => {
      return {
        ...state,
        selectedTeam: action.payload,
      };
    },
    removeSelectedTeam: (state) => {
      state.selectedTeam = {};
    },
    setIsUserCriticalInTeam: (state, action) => {
      state.isUserCriticalInTeam = action.payload;
    },
    setSelectedTeamMainMetaData: (state, action) => {
      return {
        ...state,
        selectedTeamMainMetaData: action.payload,
      };
    },
    setAuditSchemas: (state, action) => {
      return {
        ...state,
        auditSchemas: action.payload,
      };
    },
  },
});

export const {
  setTeamById,
  removeSelectedTeam,
  setIsUserCriticalInTeam,
  setSelectedTeamMainMetaData,
  setAuditSchemas,
} = teamSettingsSlice.actions;
export default teamSettingsSlice.reducer;
