import React from "react";
import { useTranslation } from "react-i18next";
import TopNavigation, {
  TopNavigationProps,
} from "@amzn/awsui-components-react/polaris/top-navigation";
import { useAppDispatch, useAppSelector } from "app/store";
import { i18nStrings, profileActions } from "./config";
import styles from "./style.module.scss";
import logo from "./GaiaLogo.svg";
import { useUiContent } from "features/ui-content";
import { useGetTeamsQuery } from "./endpoints";
import { ButtonDropdownProps } from "@amzn/awsui-components-react";
import { teamNameUpdated } from "features/audits/slice";
import { useTeamMembers } from "features/ui-content/hooks";
import { GaiaConstants } from "utils";
import { useNavigate } from "react-router-dom";
import { setIsUserCriticalInTeam } from "features/team-settings/slice";
import {
  setAuditSchemas,
  setSelectedTeamMainMetaData,
} from "features/team-settings/slice";
import { Team } from "models/schemas";
import { generateAuditDynamicSchemas } from "models/generateAuditSchemas";

const Header: React.FC = () => {
  const navigator = useNavigate();
  const { fullName, email } = useAppSelector((state) => state.auth.user);
  const { t } = useTranslation();
  let teams: Array<ButtonDropdownProps.ItemOrGroup> = [];
  let userCriticalnessInTeam = new Map<string, boolean>();
  let teamSettingMap = new Map<string, Team>();
  const { data } = useGetTeamsQuery();
  const dispatch = useAppDispatch();
  useUiContent();
  useTeamMembers();

  const handleChange: any = (val: any) => {
    navigator("/");
    updateSelectedTeam(val.id, val.text);
    updateUserCriticalnessInTeam(val.id);
    // dispatch team main meta data and team dynamic schemas
    updateTeamDataAndSchemas(val.id);
  };

  function updateTeamDataAndSchemas(selectedTeam: string) {
    dispatch(
      setSelectedTeamMainMetaData(createTeamMetaDataObject(selectedTeam))
    );
    const customAttrData =
      teamSettingMap.get(selectedTeam)?.teamCustomAttributesSchema;
    if (customAttrData?.audit) {
      const auditRelatedSchemas = generateAuditDynamicSchemas(
        customAttrData.audit
      );
      dispatch(setAuditSchemas(auditRelatedSchemas));
    }
  }

  /*
   * This is for create team meta data object including the custome attributes data
   */
  function createTeamMetaDataObject(selectedTeam: string): Team {
    const team = teamSettingMap.get(selectedTeam);
    return team!;
  }

  function updateSelectedTeam(
    selectedTeam: string,
    selectedTeamDescription: string
  ) {
    localStorage.setItem(
      GaiaConstants.SELECTED_TEAM_DESCRIPTION,
      selectedTeamDescription
    );
    localStorage.setItem(GaiaConstants.SELECTED_TEAM_NAME, selectedTeam);
    dispatch(teamNameUpdated(selectedTeam));
  }

  function updateUserCriticalnessInTeam(selectedTeam: string) {
    const isCritical = userCriticalnessInTeam.has(selectedTeam)
      ? userCriticalnessInTeam.get(selectedTeam)
      : false;
    dispatch(setIsUserCriticalInTeam(isCritical));
  }

  function getUtilities() {
    const utilities: TopNavigationProps.Utility[] = [];
    let teamDescription: string = "Choose a team";
    let teamName = localStorage.getItem(GaiaConstants.SELECTED_TEAM_NAME);

    data?.teams?.forEach((team: Team) => {
      userCriticalnessInTeam.set(team.teamId, team.isCritical);
      teamSettingMap.set(team.teamId, team);
      // select 1st team in the dropdown
      if (teamName === null || teamName === undefined || teamName === "") {
        teamName = team.teamId;
      }
      if (teamName === team.teamId) {
        teamDescription = team.teamDescription;
        updateSelectedTeam(team.teamId, teamDescription);
        dispatch(setIsUserCriticalInTeam(team.isCritical));
        updateTeamDataAndSchemas(team.teamId);
      }
      teams = [
        ...teams,
        {
          id: team.teamId,
          text: team.teamDescription,
        },
      ];
    });

    utilities.push({
      type: "menu-dropdown",
      onItemClick: (e) => {
        handleChange(e.detail);
      },
      text: teamDescription,
      items: teams,
    });

    utilities.push({
      type: "menu-dropdown",
      text: fullName,
      description: email,
      iconName: "user-profile",
      items: profileActions,
    });

    return utilities;
  }

  return (
    <div id="header" className={styles.header}>
      <TopNavigation
        i18nStrings={i18nStrings}
        identity={{
          href: "#",
          logo: { src: logo, alt: t("app_name") },
        }}
        utilities={getUtilities()}
      />
    </div>
  );
};

export default Header;
