type SearchType = "customerInfo" | "addressInfo";
type CustomerInfoSearchIdOptionType =
  | "buyerIds"
  | "buyerExemptionCertIds"
  | "addressIds";

// define the interfaces and state for data report under team
export interface ICustomerLookup {
  reportName: string;
  reportPurpose: string;
  isUserAgreed: boolean;
  searchType: SearchType;
  idInput: string;
  customerInfoSearchIdOption: CustomerInfoSearchIdOptionType;
  filesUploaded: File[];
  fileIds: string;
  isDataEncrypted: string;
}

export interface IMarketplaceSellerLookup {
  reportName: string;
  reportPurpose: string;
  isUserAgreed: boolean;
  sellerName: string;
  sellerTaxId: string;
  sellerId: string;
}

// define the interfaces and state for data report under audit
export interface ICustomerLookupConfigs {
  [key: string]: ICustomerLookup;
}

export interface IMarketplaceSellerLookupConfigs {
  [key: string]: IMarketplaceSellerLookup;
}

// define the DataCreate state interface
export interface IDataCreateState {
  customerLookup: ICustomerLookupConfigs;
  marketplaceSellerLookup: IMarketplaceSellerLookupConfigs;
}

export const BLANK_CUSTOMER_LOOKUP_CONFIG: ICustomerLookup = {
  reportName: new Date().toISOString().substring(0, 10),
  reportPurpose: "",
  isUserAgreed: false,
  searchType: "addressInfo",
  idInput: "",
  customerInfoSearchIdOption: "buyerIds",
  filesUploaded: [],
  fileIds: "",
  isDataEncrypted: "Yes",
};

export const BLANK_MARKETPLACE_SELLER_LOOKUP_CONFIG: IMarketplaceSellerLookup =
  {
    reportName: new Date().toISOString().substring(0, 10),
    reportPurpose: "",
    isUserAgreed: false,
    sellerName: "",
    sellerTaxId: "",
    sellerId: "",
  };

export const TEAM_LEVEL_DATA_REPORT_KEY_NAME = "team";
// define the intial state for each type of report under team/audit
export const INITIAL_CUSTOMER_LOOKUP_STATE: ICustomerLookupConfigs = {
  [TEAM_LEVEL_DATA_REPORT_KEY_NAME]: BLANK_CUSTOMER_LOOKUP_CONFIG,
};

export const INITIAL_MARKETPLACE_SELLER_LOOKUP_STATE: IMarketplaceSellerLookupConfigs =
  {
    [TEAM_LEVEL_DATA_REPORT_KEY_NAME]: BLANK_MARKETPLACE_SELLER_LOOKUP_CONFIG,
  };

export const getBlankWorkflowConfig = (queryType: string) => {
  // switch query type to define the state name
  switch (queryType) {
    case "CUSTOMER_INFORMATION_LOOKUP":
      return BLANK_CUSTOMER_LOOKUP_CONFIG;
    case "CUSTOMER_INFORMATION_LOOKUP_BY_BRAAVOS":
      return BLANK_CUSTOMER_LOOKUP_CONFIG;
    case "BrazilMarketPlaceSellerLookup":
      return BLANK_MARKETPLACE_SELLER_LOOKUP_CONFIG;
    default:
      return BLANK_CUSTOMER_LOOKUP_CONFIG;
  }
};
