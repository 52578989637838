import { z } from "zod";

/*
 * Keys used for refering to the schema for the real data schema from backend for custom attributes or the data schema in UI form
 */
export const CUSTOM_ATTRIBUTES_DATA_MAP_KEY_NAME = "customAttributesDataMap";

/*
 * Schema definition for a single attribute definition defined by user and retrieved from backend
 */
export const customAttributeDataObjectSchema = z.object({
  fieldName: z.string(),
  attributeName: z.string(),
  hintText: z.string().optional(),
  description: z.string().optional(),
  formFieldType: z.string(),
  inputValueType: z.string().optional(),
  required: z.boolean().optional(),
  validationRuleList: z
    .array(
      z.object({
        Message: z.string().optional(),
        Expression: z.string().optional(),
        Value: z.string().optional(),
      })
    )
    .optional(),
  dropdownValueList: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    )
    .optional(),
});

/*
 * Schema definition for all custom attribute definition defined by user and retrieved from backend
 */
export const customAttributesDataObjectSchema = z.array(
  customAttributeDataObjectSchema
);

export type CustomAttribute = z.infer<typeof customAttributeDataObjectSchema>;
export type CustomAttributes = z.infer<typeof customAttributesDataObjectSchema>;

/*
 * Basic Zod schema for custom attributes: this is for building the Data Form Schema either for creating or editing entities
 */
const zodSchemaMap: Record<string, z.ZodTypeAny> = {
  Text: z.string(),
  Dropdown: z.string(),
  Date: z.string(), // ControlledDatePicker can only be string. We need to convert to number when trigger the api
  Number: z.string(), // ControlledInput can only be string, it can't be number. We will convert it into number when trigger the api
  Checkbox: z.boolean(),
};

/*
 * Zod schema for custom attributes shown on UI. This contains all the validation rules for each attribute
 * @param customAttributesSchemaData: the custom attributes data definition from backend
 */
export const generateDynamicZodSchema = (
  customAttributesSchemaData: CustomAttributes
) => {
  const customAttributeSchema = customAttributesSchemaData.reduce(
    (acc, attribute) => {
      const {
        fieldName,
        attributeName,
        required,
        inputValueType,
        formFieldType,
        validationRuleList,
      } = attribute;

      let schema = zodSchemaMap[formFieldType];
      if (!schema) {
        throw new Error(`Unsupported field type: ${formFieldType}`);
      }

      // add refinement for "required" attribute
      if (!required) {
        schema = schema.optional().nullable();
      }

      // add schema refinement based on value type and validation rules
      // Only three data to be considered: number, string, boolean currently
      switch (inputValueType) {
        case "string":
          schema = updateSchemaForStringInput(
            schema,
            fieldName,
            validationRuleList
          );
          break;
        case "number":
          schema = updateSchemaForNumberInput(
            schema,
            fieldName,
            validationRuleList
          );
          schema = schema.transform((val) => Number(val));
          break;
        default:
          break;
      }

      acc[attributeName] = schema;
      return acc;
    },
    {} as Record<string, z.ZodTypeAny>
  );
  var res = z.object(customAttributeSchema);
  return res;
};

/*
 * Refine the schema for string input type, add validation for string input
 * @param schema
 * @param fieldName
 * @param validationRuleList
 */
const updateSchemaForStringInput = (
  schema: z.ZodTypeAny,
  fieldName: string,
  validationRuleList: any
) => {
  if (validationRuleList && validationRuleList.length > 0) {
    validationRuleList.forEach(
      (validation: { Expression: any; Value: any; Message: any }) => {
        const { Expression, Value, Message } = validation;
        const valueNum = Number(Value);

        if (Expression) {
          // add more if/else if we have more scenarios
          if (Expression === "maxLength") {
            schema = schema.refine(
              (val) => {
                if (val !== undefined && val !== null) {
                  return val.length <= valueNum!;
                } else {
                  return true;
                }
              },
              {
                message:
                  Message ||
                  `String size must be less than or equal to ${valueNum}`,
              }
            );
          }
        }
      }
    );
  }
  return schema;
};

/*
 * Refine the schema for number input type, add validation for number input
 * @param schema
 * @param fieldName
 * @param validationRuleList
 */
const updateSchemaForNumberInput = (
  schema: z.ZodTypeAny,
  fieldName: string,
  validationRuleList: any
) => {
  schema = schema.refine(
    (val) => {
      if (val) {
        return !isNaN(Number(val));
      } else {
        return true;
      }
    },
    {
      message: `Must be a number`,
    }
  );

  if (validationRuleList && validationRuleList.length > 0) {
    validationRuleList.forEach(
      (validation: { Expression: any; Value: any; Message: any }) => {
        const { Expression, Value, Message } = validation;
        const valueNum = Number(Value);

        if (Expression) {
          switch (Expression) {
            case ">":
              schema = schema.refine(
                (val) => !val || (val && val > valueNum!),
                {
                  message:
                    Message ||
                    `${fieldName} should be greater than ${valueNum}`,
                }
              );
              break;
            case ">=":
              schema = schema.refine(
                (val) => !val || (val && val >= valueNum!),
                {
                  message:
                    Message ||
                    `${fieldName} should be greater than or equal to ${valueNum}`,
                }
              );
              break;

            case "<":
              schema = schema.refine(
                (val) => !val || (val && val < valueNum!),
                {
                  message:
                    Message || `${fieldName} should be less than ${valueNum}`,
                }
              );
              break;
            case "<=":
              schema = schema.refine(
                (val) => !val || (val && val <= valueNum!),
                {
                  message:
                    Message ||
                    `${fieldName} should be less than or equal to ${valueNum}`,
                }
              );
              break;
            // Add more cases for other expressions as needed
            default:
              break;
          }
        }
      }
    );
  }
  return schema;
};
