import { typeToReportName } from "pages/data/DataConfig";

export const queryWorkflowLabelComparator = (teamName: string) => {
  return (a: any, b: any) =>
    typeToReportName(teamName, a.queryWorkflowName)
      .toLowerCase()
      .localeCompare(
        typeToReportName(teamName, b.queryWorkflowName).toLowerCase()
      );
};

export const queryDataStringFieldComparator = (fieldName: string) => {
  if (fieldName === "queryName") {
    return (a: any, b: any) =>
      a.queryName.toLowerCase().localeCompare(b.queryName.toLowerCase());
  } else {
    throw new Error("query field name is unknown");
  }
};
