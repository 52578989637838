import React from "react";
import { Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";

/**
 * Hook for handling amplify auth events
 */
export const useOnAuthHandler = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const authListener = (data: any) => {
      if (data.payload.event === "customOAuthState") {
        navigate(data.payload.data, { replace: true });
      }
    };

    Hub.listen("auth", authListener);

    return () => Hub.remove("auth", authListener);
  }, [navigate]);
};
