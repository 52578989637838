import { PropertyFilterProps } from "@amzn/awsui-components-react/polaris";

export const commonPropertyFilterI18nStrings: PropertyFilterProps.I18nStrings =
  {
    filteringAriaLabel: "your choice",
    dismissAriaLabel: "Dismiss",

    groupValuesText: "Values",
    groupPropertiesText: "Properties",
    operatorsText: "Operators",

    operationAndText: "and",
    operationOrText: "or",

    operatorLessText: "Less than",
    operatorLessOrEqualText: "Less than or equal",
    operatorGreaterText: "Greater than",
    operatorGreaterOrEqualText: "Greater than or equal",
    operatorContainsText: "Contains",
    operatorDoesNotContainText: "Does not contain",
    operatorEqualsText: "Equals",
    operatorDoesNotEqualText: "Does not equal",
    operatorStartsWithText: "Starts with",

    editTokenHeader: "Edit filter",
    propertyText: "Property",
    operatorText: "Operator",
    valueText: "Value",
    cancelActionText: "Cancel",
    applyActionText: "Apply",
    allPropertiesLabel: "All properties",

    tokenLimitShowMore: "Show more",
    tokenLimitShowFewer: "Show fewer",
    clearFiltersText: "Clear filters",
    removeTokenButtonAriaLabel: (token) =>
      `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
    enteredTextLabel: (text) => `Use: "${text}"`,
  };
