import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Review } from "models/schemas";
export const reviewSlice = createSlice({
  initialState: [] as Review[],
  name: "reviews",
  reducers: {
    updateReviews: (state, action: PayloadAction<Review[]>) => {
      return action.payload;
    },
    addReview: (state, action: PayloadAction<Review[]>) => {
      return [...state, ...action.payload];
    },
    updateReview: (state, action: PayloadAction<Review>) => {
      return state.map((review) => {
        if (review.reviewId === action.payload.reviewId) {
          return action.payload;
        }
        return review;
      });
    },
    removeReview: (state, action: PayloadAction<Review>) => {
      return state.filter(
        (review) => review.reviewId !== action.payload.reviewId
      );
    },
  },
});
export const { addReview, removeReview, updateReview, updateReviews } =
  reviewSlice.actions;
