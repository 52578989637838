import { useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";

export const addToColumnDefinitions = (
  columnDefinitions,
  propertyName,
  columns
) =>
  columnDefinitions.map((colDef) => {
    const column = (columns || []).find((col) => col.id === colDef.id);
    return {
      ...colDef,
      [propertyName]: (column && column[propertyName]) || colDef[propertyName],
    };
  });

export const mapWithColumnDefinitionIds = (
  columnDefinitions,
  propertyName,
  items
) =>
  columnDefinitions.map(({ id }, i) => ({
    id,
    [propertyName]: items[i],
  }));

export function useColumnWidths(storageKey, columnDefinitions) {
  const [widths, saveWidths] = useLocalStorage(storageKey);

  function handleWidthChange(event) {
    saveWidths(
      mapWithColumnDefinitionIds(
        columnDefinitions,
        "width",
        event.detail.widths
      )
    );
  }
  const memoDefinitions = useMemo(() => {
    return addToColumnDefinitions(columnDefinitions, "width", widths);
  }, [widths, columnDefinitions]);

  return [memoDefinitions, handleWidthChange];
}
