export class GaiaConstants {
  static readonly SELECTED_TEAM_NAME = "Team";
  static readonly SELECTED_TEAM_DESCRIPTION = "TeamDescription";
  // Data related pages/components
  static readonly CUSTOMER_INFORMATION_LOOKUP = "CustomerInformationLookup";
  static readonly CUSTOMER_INFORMATION_LOOKUP_BY_BRAAVOS =
    "CustomerInformationLookupByBraavos";
  static readonly BRAZIL_MARKET_PLACE_SELLER_LOOKUP =
    "BrazilMarketPlaceSellerLookup";
}
