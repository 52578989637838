export const isValidAuditId = (auditId: string) => {
  return (
    auditId.match(
      /^audit([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}){1}$/
    ) !== null
  );
};

export const isValidBrazilCpf = (id: string | undefined) => {
  return id && id.match(/^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/) !== null;
};

export const isValidBrazilCnpj = (id: string | undefined) => {
  return id && id.match(/^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/) !== null;
};

export const hasMoreThanTenIds = (data: string | undefined) => {
  throw new Error("Function not implemented.");
};
