import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "@aws-amplify/auth";

import { RootState } from "app/store";

import { refreshSession } from "./utils";

export const authenticate = createAsyncThunk(
  "auth/authenticate",
  async (expired: boolean, { getState }) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const userAttributes = JSON.parse(JSON.stringify(cognitoUser.attributes));
      const userIdentity = JSON.parse(userAttributes.identities)[0];

      const currentSession = expired
        ? await refreshSession(cognitoUser)
        : cognitoUser.signInUserSession;
      const userSession = JSON.parse(JSON.stringify(currentSession));
      const idToken = userSession.idToken;

      return {
        isAuthenticated: true,
        jwtToken: idToken.jwtToken,
        expiration: idToken.payload["exp"],
        roles: idToken.payload["custom:ldapGroups"],
        user: {
          alias: userIdentity.userId,
          firstName: userAttributes.given_name,
          lastName: userAttributes.family_name,
          fullName: `${userAttributes.given_name} ${userAttributes.family_name}`,
          email: userAttributes.email,
        },
      };
    } catch (e) {
      const { providerName } = (getState() as RootState).settings.auth;
      Auth.federatedSignIn({
        customProvider: providerName,
        customState: window.location.pathname,
      });
    }
  }
);
