import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICustomerLookupConfigs,
  IDataCreateState,
  IMarketplaceSellerLookupConfigs,
  INITIAL_CUSTOMER_LOOKUP_STATE,
  INITIAL_MARKETPLACE_SELLER_LOOKUP_STATE,
} from "./definitions";

const initialState: IDataCreateState = {
  customerLookup: INITIAL_CUSTOMER_LOOKUP_STATE,
  marketplaceSellerLookup: INITIAL_MARKETPLACE_SELLER_LOOKUP_STATE,
};

export const dataCreateSlice = createSlice({
  name: "dataCreate",
  initialState,
  reducers: {
    customerLookupUpdated: (
      state,
      action: PayloadAction<ICustomerLookupConfigs>
    ) => {
      const originalState = state.customerLookup;
      const payload = action.payload;
      const mergedState = { ...state.customerLookup };

      for (const key in payload) {
        if (originalState.hasOwnProperty(key)) {
          mergedState[key] = {
            ...originalState[key],
            ...payload[key],
          };
        } else {
          mergedState[key] = payload[key];
        }
      }

      state.customerLookup = mergedState;
    },

    marketplaceSellerLookupUpdated: (
      state,
      action: PayloadAction<IMarketplaceSellerLookupConfigs>
    ) => {
      const originalState = state.marketplaceSellerLookup;
      const payload = action.payload;
      const mergedState = { ...state.marketplaceSellerLookup };

      for (const key in payload) {
        if (originalState.hasOwnProperty(key)) {
          mergedState[key] = {
            ...originalState[key],
            ...payload[key],
          };
        } else {
          mergedState[key] = payload[key];
        }
      }

      state.marketplaceSellerLookup = mergedState;
    },
  },
});

export const { customerLookupUpdated, marketplaceSellerLookupUpdated } =
  dataCreateSlice.actions;

export default dataCreateSlice.reducer;
