import { Audit, Task } from "models/schemas";
import moment from "moment";

export const toUnixEpochTime = (dateString?: string) => {
  if (!dateString || dateString === undefined) return 0;
  return new Date(dateString).getTime() / 1000;
};

export const toDateString = (date: number) => {
  if (date) return moment.utc(date * 1000).format("YYYY-MM-DD");
  else return "";
};

export const toAuditDateString = (audit: Audit) => {
  audit.auditStartDateString = toDateString(audit.auditStartDate);
  audit.auditEndDateString = toDateString(audit.auditEndDate);
  audit.auditInitiationDateString = toDateString(audit.auditInitiationDate);
  audit.auditCloseDateString = toDateString(audit.auditCloseDate!);
  audit.statuteOfLimitationsString = toDateString(audit.statuteOfLimitations!);
  return audit;
};

export const toTaskDateString = (task: Task) => {
  task.plannedStartDateString = toDateString(Number(task.plannedStartDate));
  task.actualStartDateString = toDateString(Number(task.actualStartDate));
  task.dueDateString = toDateString(Number(task.dueDate));
  task.actualCompletionDateString = toDateString(
    Number(task.actualCompletionDate)
  );
  return task;
};

export const extractYear = (date: number) => {
  return moment.utc(date * 1000).format("YYYY");
};
