import { mainApi } from "services/main";
import { Team, TaxArea } from "models/schemas";
import {
  GetTeamByIdRequest,
  GetTaxAreaByIdRequest,
  GetTaxAreaByIdResponse,
  ListTaxAreaRequest,
  ListTaxAreaResponse,
  CreateTaxAreaResponse,
  UpdateTaxAreaResponse,
} from "./definitions";
import { CreateTaxAreaInput } from "components/create-taxarea-form";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeamById: builder.query<Team, GetTeamByIdRequest>({
      query: (request: GetTeamByIdRequest) => ({
        url: "team/" + request.teamId,
        params: {
          teamName: request.teamName,
        },
      }),
    }),
    getTaxAreaById: builder.query<
      GetTaxAreaByIdResponse,
      GetTaxAreaByIdRequest
    >({
      query: (request: GetTaxAreaByIdRequest) => ({
        url: "taxArea/" + request.taxAreaId,
        params: {
          teamName: request.teamName,
        },
      }),
    }),
    listTaxAreaByTeam: builder.query<ListTaxAreaResponse, ListTaxAreaRequest>({
      query: (request: ListTaxAreaRequest) => ({
        url: "taxAreas/" + request.teamName,
      }),
    }),
    createTaxArea: builder.mutation<CreateTaxAreaResponse, CreateTaxAreaInput>({
      query: (request?: CreateTaxAreaInput) => ({
        url: "taxArea",
        body: request,
        method: "POST",
      }),
    }),
    updateTaxArea: builder.mutation<UpdateTaxAreaResponse, TaxArea>({
      query: (request?: TaxArea) => ({
        url: "taxArea/" + request?.taxAreaId,
        body: request,
        method: "PUT",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTeamByIdQuery,
  useGetTaxAreaByIdQuery,
  useListTaxAreaByTeamQuery,
  useCreateTaxAreaMutation,
  useUpdateTaxAreaMutation,
} = extendedApi;
