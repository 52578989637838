import React from "react";
import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel";
import { useTranslation } from "react-i18next";

export const Help: React.FC = () => {
  const { t } = useTranslation();
  const title = t("pages.team_settings.title");

  return (
    <HelpPanel header={<h2>{title}</h2>}>
      <p>Write the help content here</p>
    </HelpPanel>
  );
};

export default Help;
